/* global graphql */
import React from 'react';
import TemplateWrapper from '../components/TemplateWrapper';
import HeaderExp from '../components/header/header-experiences';
import InfoSectionExp from '../components/InfoSection/InfoSectionExp';
import RainbowCTA from '../components/RainbowCTA';
import {Link} from "gatsby";
import { graphql } from "gatsby";

const ExperiencesPage = props =>
  (<TemplateWrapper location={props.location}>
    <HeaderExp location={props.location.pathname} />
    <main className="ExperiencesPage">
      <section className="Section ExperiencesPage-heading">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-6 col-md-offset-1 ExperiencesPage-heading-title">
                <h1 className="Heading Heading-d1 light">Consumer</h1>
              </div>
              <div className="col-xs-14 col-md-6 ExperiencesPage-heading-cta">
                <Link to="/waitlist/" className="BtnFilled BtnFilled-large purple uppercase" onClick={()=>window.analytics.track('Clicked Waitlist CTA',{component:'experiencesPage'})}><span>Sign up</span></Link>
                <p className="Caption Caption-C3 light uppercase">Available Late 2019</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section ExperiencesPage-info1">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <hr className="ExperiencesPage-heading-hr" />
              </div>
              <div className="col-xs-14 col-md-6 col-md-offset-1">
                <InfoSectionExp data={props.data.allDataJson.edges[0].node.ExpInfo1} />
              </div>
              <div className="col-xs-14 col-md-5 col-md-offset-1">
                <img src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/experiences-football.png" alt="wifi at event"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section ExperiencesPage-info2">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-4 col-md-offset-1 hide-xs hide-sm">
                <img src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/experiences-beach.png" alt="wifi on vacation"/>
              </div>
              <div className="col-xs-14 col-md-7 col-md-offset-1">
                <hr className="ExperiencesPage-heading-hr" />
                <InfoSectionExp data={props.data.allDataJson.edges[0].node.ExpInfo2} />
              </div>
              <div className="col-xs-14 hide-md hide-lg hide-xl">
                <img src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/experiences-beach.png" alt="wifi on vacation"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <RainbowCTA title="What are you waiting for?"/>
    </main>
  </TemplateWrapper>);

export default ExperiencesPage;


export const pageQuery = graphql`
  query ExperiencesQuery {
    allDataJson {
      edges {
        node {
          ExpInfo1 {
            title,
            subtitle,
            paragraph1,
            paragraph2,
            callout,
            paragraph3
          }
          ExpInfo2 {
            title,
            subtitle,
            paragraph1,
            paragraph2,
            callout,
            paragraph3
          }
        }
      }
    }
  }
`;
